
import {
  IonCard,
    IonHeader,
  IonToolbar,
  IonCardContent,
  IonIcon,
  IonCardHeader,IonTitle,
  IonCardSubtitle,
  IonButtons,
  IonButton,
  IonRadio, IonRadioGroup, toastController, IonModal, IonProgressBar, IonImg, IonCol, IonGrid, IonRow,
  IonItem,
  IonLabel, IonList, IonContent, IonPage
} from '@ionic/vue';
import {warning, chevronBackSharp, ribbon} from 'ionicons/icons';
import {computed, defineComponent, ref} from 'vue';
import {randomQuestion} from '@/data/20220326';

export default defineComponent({
  name: 'TestQuestion',
  components: {
    IonToolbar,
    IonIcon,
    IonContent, IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonCard,
    IonTitle,
    IonHeader,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonButtons,
    IonButton,
    IonRadioGroup,
    IonRadio,
    IonModal,
    IonProgressBar,
    IonCol, IonGrid, IonRow
  },
  setup() {
    const index = ref(1.0);
    const opSelect = ref();
    const que = computed(() => {
      const randomQuestion1 = randomQuestion(10);
      return Object.assign({}, randomQuestion1[index.value - 1], {num: `${index.value} / ${randomQuestion1.length}`});
    });
    const showAnswer = ref(false);
    const progress = ref(1);
    const progressTime = ref(60000);
    const item = ref({
      a: false,
      b: false,
      c: false,
      d: false,
    });
    const xhTime = ref(0);
    const rcolor = ref('secondary');
    const progressColor = ref('secondary');
    const prog = setInterval(()=>{
      progressTime.value-=200;
      if(progressTime.value > 0){
        progress.value = progressTime.value/60000;
        if(progressTime.value < 30000){
          progressColor.value = 'warning';
        }
        if(progressTime.value < 10000){
          progressColor.value = 'danger';
        }
      }else{
        nextQue();
      }
    }, 200);

    function hy() {
      progressColor.value = 'secondary';
      progress.value = 1;
      xhTime.value += (60000 - progressTime.value);
      progressTime.value = 60000;
    }

    function extracted(val: string) {
      return val == que.value.answer.trim().toLowerCase();
    }

    const anResult = {
      error: 0,
      success: 0,
    };

    function checkAnswer(e: any) {
      if (e.detail.value != null && e.detail.value != '') {
        if (!extracted(e.detail.value)) {
          anResult.error++;
          rcolor.value = 'danger';
        } else {
          anResult.success++;
          rcolor.value = 'secondary';
        }
        setTimeout(() => {
          nextQue();
        }, 500);
      }
    }

    function nextQue() {
      if (index.value < 10) {
        index.value++;
        opSelect.value = null;
        hy();
      } else {
        showAnswer.value = true;
        clearInterval(prog);
      }
    }

    return {
      progress,
      progressColor,
      warning,
      chevronBackSharp,
      que,
      showAnswer,
      item,
      checkAnswer,
      nextQue,
      opSelect,
      rcolor,
      anResult,
      ribbon,
      xhTime
    };
  }
});
