
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonButton,
  IonCheckbox,
  toastController,
  CheckboxChangeEventDetail,
  IonCol,
  IonGrid,
  IonRow,
  IonList,
  IonItem,
  IonLabel,
  IonToolbar, IonIcon, IonTitle, IonHeader,
} from '@ionic/vue';
import {warning, chevronBackSharp} from 'ionicons/icons';
import {computed, defineComponent, ref} from 'vue';
import {QuestionData} from '@/data/20220326';
import {useStorage} from 'vue3-storage';

export default defineComponent({
  name: 'SequenceQuestion',
  components: {
    IonTitle,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonCard,
    IonCardContent,
    IonContent,
    IonPage,
    IonCardHeader,
    IonCardSubtitle,
    IonButtons,
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonRow,
    IonList,
    IonItem,
    IonLabel,
  },
  setup() {
    const index = ref(1);
    const storage = useStorage();
    let storageIndex = storage.getStorageSync('que-s-index');
    if (storageIndex) {
      index.value = storageIndex;
    }
    const que = computed(() => {
      return Object.assign({}, QuestionData[index.value - 1], {num: `${index.value} / ${QuestionData.length}`});
    });
    const showAnswer = ref(false);
    const item = ref({
      a: false,
      b: false,
      c: false,
      d: false,
    });
    const selectOp = ref();

    function extracted(val: string) {
      return val == que.value.answer.trim().toLowerCase();
    }

    function checkAnswer(e: any) {
      if (e.detail.checked) {
        Object.assign(item.value, {
          a: false,
          b: false,
          c: false,
          d: false,
        });
        let a: 'a' | 'b' | 'c' | 'd' = e.detail.value;
        item.value[a] = true;
        if (!extracted(e.detail.value)) {
          massage('回答错误');
        } else {
          // aa
        }
      }
    }

    async function massage(msg: string) {
      const toast = await toastController
          .create({
            color: 'warning',
            message: msg,
            duration: 2000
          })
      await toast.present();
    }

    function nextQue() {
      if (index.value < QuestionData.length) {
        index.value++;
        storage.setStorageSync('que-s-index', index.value);
        Object.assign(item.value, {
          a: false,
          b: false,
          c: false,
          d: false,
        });
      }
    }

    function preQue() {
      if (index.value > 1) {
        index.value--;
        storage.setStorageSync('que-s-index', index.value);
        Object.assign(item.value, {
          a: false,
          b: false,
          c: false,
          d: false,
        });
      }
    }

    return {
      warning,
      chevronBackSharp,
      que,
      showAnswer,
      item,
      selectOp,
      checkAnswer,
      nextQue,
      preQue,
    };
  }
});
